<route>
  {
    "meta": {
      "permission": [
        "equipements.view_equipement",
        "equipements.change_equipement"
      ]
    }
  }
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              maintenance.pk
                ? `${$t('edit')} ${$t('equipment_maintenance')}`
                : `${$t('add', { value: $t('equipment_maintenance') })}`
            "
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <br />
            <v-row class="py-5">
              <!-- Fecha de realización del mantenimiento de equipo -->
              <v-col cols="12" sm="3">
                <i-date-picker
                  v-model="maintenance.date"
                  :load="load"
                  :label="$tc('date', 1)"
                  color="primary"
                />
              </v-col>

              <!-- Tipo de mantenimiento -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="mode"
                  :name="$t('mode')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.mode"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      { state: 'Corrective', abbr: 'corrective' },
                      { state: 'Preventive', abbr: 'preventive' }
                    ]"
                    :label="$t('mode')"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Operador -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="operator"
                  :name="$t('operator')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="maintenance.operator"
                    :error-messages="errors[0]"
                    :label="$t('operator')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Descripción del mantenimiento -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="description"
                  :name="$t('description')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    v-model="maintenance.description"
                    :error-messages="errors[0]"
                    :label="$t('description')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Tipo de pago -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="pay"
                  :name="$t('paymenttype')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.pay"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      { state: $t('cc'), abbr: 'cc' },
                      { state: $t('cash'), abbr: 'cash' },
                      { state: $t('check'), abbr: 'check' }
                    ]"
                    :label="$t('paymenttype')"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Pago referencial -->
              <v-col cols="12" sm="3">
                <ValidationProvider
                  vid="expense"
                  :name="$t('payment_ref')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    :disabled="maintenance.recurrent && maintenance.pk"
                    v-model="maintenance.expense"
                    :error-messages="errors[0]"
                    :label="$t('payment_ref')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Botón de clonación de pago -->
              <v-col cols="1" v-if="maintenance.recurrent && maintenance.pk">
                <i-btn
                  :title="$tc('clone', 1)"
                  outlined
                  classes="mx-1"
                  icon="fa-clone"
                  color="secondary"
                  @click="maintenance.payment = maintenance.expense"
                />
              </v-col>

              <!-- Monto de pago -->
              <v-col
                cols="12"
                sm="2"
                v-if="maintenance.pk && maintenance.recurrent"
              >
                <ValidationProvider
                  vid="payment"
                  :name="$tc('payment', 1)"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    type="number"
                    step="0.01"
                    v-model="maintenance.payment"
                    color="secondary"
                    :label="$tc('payment', 1)"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Recurrencia del mantenimiento del equipo -->
              <v-col cols="12" sm="3" v-if="maintenance.recurrent">
                <ValidationProvider
                  vid="type_recurrent"
                  :name="$t('type_recurrent')"
                  :rules="{ required: maintenance.recurrent }"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.type_recurrent"
                    item-text="state"
                    item-value="abbr"
                    :items="[
                      { state: $t('yearly'), abbr: 'year' },
                      { state: $t('monthly'), abbr: 'month' },
                      { state: $t('weekly'), abbr: 'week' }
                    ]"
                    :label="$t('type_recurrent')"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Días en que se realiza el mantenimiento del equipo -->
              <v-col
                cols="12"
                sm="3"
                v-if="
                  maintenance.recurrent && maintenance.type_recurrent === 'week'
                "
              >
                <ValidationProvider
                  vid="days"
                  :name="$t('days')"
                  :rules="{ required: maintenance.type_recurrent === 'week' }"
                  v-slot="{ errors }"
                >
                  <v-select
                    autocomplete="off"
                    outlined
                    :error-messages="errors[0]"
                    v-model="maintenance.day"
                    :items="days"
                    :label="$t('days')"
                    item-text="value"
                    item-value="key"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <!-- Switch de recurrencia -->
              <v-col cols="12" sm="3">
                <v-switch
                  v-model="maintenance.recurrent"
                  :label="$tc('recurrent', 1)"
                ></v-switch>
              </v-col>

              <!-- Botón para mostrar u ocultar la lista de mantenimientos -->
              <v-col cols="12" sm="4">
                <v-btn
                  :color="showMaintenances ? 'error' : 'secondary'"
                  :title="`${$t('equipment_maintenance')}`"
                  @click="showMaintenances = !showMaintenances"
                >
                  <v-icon class="mr-1" small>
                    {{ showMaintenances ? 'fa-times' : 'fa-eye' }}
                  </v-icon>
                  {{ showMaintenances ? $t('hide') : $t('show') }}
                  {{ $t('equipment_maintenance') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <v-tabs
              v-if="showMaintenances"
              color="primary"
              v-model="tabs"
              class="mb-2"
              next-icon="fa-chevron-right"
              prev-icon="fa-chevron-left"
              centered
              show-arrows
              :icons-and-text="$vuetify.breakpoint.smAndUp"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab href="#tab-recurrent">
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="mt-2"
                  v-text="$tc('recurrent', 2)"
                ></span>
                <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
                  >fas fa-reply</v-icon
                >
              </v-tab>
              <v-tab href="#tab-regular">
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="mt-2"
                  v-text="'regular'"
                ></span>
                <v-icon :medium="$vuetify.breakpoint.smAndUp" dark
                  >fas fa-arrow-down</v-icon
                >
              </v-tab>
            </v-tabs>
            <i-table
              v-if="showMaintenances"
              :title="$tc('equipment_maintenance', 2)"
              :headers="headers"
              app="equipements.equipement"
              api="maintenance"
              dontCreate
              dontEdit
              :opt="{
                equipement_id: maintenance.equipement_id,
                recurrent: tabs === 'tab-recurrent'
              }"
            >
              <template v-slot:item.date="{ item }">
                <span v-text="dateFormat(item.date)"></span>
              </template>
              <template v-slot:item.next_date="{ item }">
                <span v-text="dateFormat(item.next_date)"></span>
              </template>
            </i-table>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'equipment-maintenance',
  data() {
    return {
      tabs: 'tab-recurrent',
      load: false,
      showMaintenances: false,
      maintenance: {
        date: '',
        operator: '',
        description: '',
        equipement_id: 0,
        expense: 0.0,
        recurrent: false,
        payment: 0.0,
        day: '',
        type_recurrent: ''
      },
      days: [
        { key: 'mon', value: this.$t('days_list.mon') },
        { key: 'tue', value: this.$t('days_list.tue') },
        { key: 'wed', value: this.$t('days_list.wed') },
        { key: 'thu', value: this.$t('days_list.thu') },
        { key: 'fri', value: this.$t('days_list.fri') },
        { key: 'sat', value: this.$t('days_list.sat') },
        { key: 'sun', value: this.$t('days_list.sun') }
      ]
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('date'), value: 'date' },
        { text: this.$t('next_date'), value: 'next_date' },
        { text: this.$t('description'), value: 'description' },
        { text: this.$t('operator'), value: 'operator' },
        {
          text: this.equipementBtn.delete ? this.$t('action') : '',
          value: this.equipementBtn.delete ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    equipementBtn() {
      return {
        delete:
          this.getPermissions(['equipements.change_equipement']) || this.isAdmin
      }
    }
  },
  methods: {
    /**
     * submit
     * Método que envía los datos de mantenimiento de equipo.
     * Dependiendo de si hay un identificador de mantenimiento, se crea un nuevo
     * registro o se edita uno existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        this.maintenance.pk
          ? await this.$api.maintenance.edit({
              pk: this.maintenance.pk,
              form: this.maintenance
            })
          : await this.$api.maintenance.create({
              form: this.maintenance
            })

        this.$toast.success(
          `${this.$tc('equipment_maintenance', 1)} ${this.$tc(
            this.maintenance.pk ? 'edited' : 'created',
            2
          )}`
        )
        if (!this.maintenance.pk) {
          const audio = new Audio(require('@/../public/sounds/sound.mp3'))
          audio.play()
        }
        this.$router.push({ name: 'equipment' })
      } finally {
        this.load = false
      }
    },
    /**
     * getMaintenance
     * Método para consultar los datos de mantenimiento según el identificador
     * proporcionado en la ruta.
     * Si se trata de una creación, inicializa un nuevo objeto de mantenimiento;
     * de lo contrario, carga los datos del mantenimiento existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getMaintenance() {
      this.loading = true
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      const equipmentId = this.codification({
        mode: 'decode',
        code: this.$route.params.equipment
      })
      this.maintenance.equipement_id = equipmentId
      if (validation === 'create') {
        this.initializeMaintenance(equipmentId)
      } else {
        equipmentId > 0
          ? this.loadMaintenance(equipmentId)
          : this.$router.push({ name: 'equipment' })
      }
    },
    /**
     * initializeMaintenance
     * Método para inicializar un nuevo objeto de mantenimiento.
     *
     * @param {string} equipmentId
     *        - ID del equipo que se asociará al mantenimiento.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    initializeMaintenance(equipmentId) {
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
        this.$route.meta.title = this.$t('create', { model: '' })
      }
      this.maintenance = {
        date: '',
        operator: '',
        description: '',
        equipement_id: equipmentId,
        expense: 0.0,
        recurrent: false,
        payment: 0.0,
        day: '',
        type_recurrent: ''
      }
    },
    /**
     * loadMaintenance
     * Método para cargar los datos de un mantenimiento existente
     * a partir del identificador proporcionado.
     *
     * @param {string} equipementId - ID del mantenimiento a cargar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    loadMaintenance(equipementId) {
      this.$api.maintenance
        .show({ pk: equipementId })
        .then((res) => {
          this.maintenance = res.data
          this.$route.meta.title = this.$t('edit', { model: '' })
        })
        .catch(() => this.$router.push({ name: 'equipment' }))
    }
  },
  mounted() {
    this.getMaintenance()
  }
}
</script>
